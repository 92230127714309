<template>
    <div class="xulybanve">
        <div class="container-pregres" v-if="screen == 'loading'">
            <div class="column justify-center">
                <div>
                    <img src="@/assets/images/money_payment.gif" alt="" />
                </div>
                <div class="spinner">
                    <img
                        class="spinner-img"
                        src="@/assets/images/Spinner-1s-200px.baaf66dd.gif"
                        alt=""
                    />
                    <p class="spinner-text color-primary">Đang thực hiện thanh toán</p>
                </div>
                <div class="progres">
                    <div class="progres-bar"></div>
                </div>
            </div>
        </div>
        <div class="container-pregres" v-if="screen == 'success'">
            <div class="banve">
                <div class="banve-img py-2">
                    <img
                        src="@/assets/images/payment-success.png"
                        alt=""
                        style="width: 100%"
                    />
                    <img class="img-success" src="@/assets/images/success.gif" alt="" />
                </div>
                <p class="spinner-text color-success py-2">Bán vé thành công!</p>

                <div class="banve-info">
                    <div class="banve-info-left pa-2">
                        <p class="py-1">Tổng tiền:</p>
                        <p class="py-1">Số điện thoại:</p>
                        <p class="py-1">Hình thức thanh toán:</p>
                    </div>
                    <div class="banve-info-right pa-2">
                        <p class="py-1">
                            {{
                                model.SoLuong
                                    ? `${$MoneyFormat(
                                          (ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                              $t(
                                                  "MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru",
                                              )
                                          ] == "true"
                                              ? model.GiaVeSauGiamTru
                                              : model.GiaVe) * model.SoLuong,
                                      )} đồng`
                                    : `${$MoneyFormat(
                                          ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                              $t(
                                                  "MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru",
                                              )
                                          ] == "true"
                                              ? model.GiaVeSauGiamTru
                                              : model.GiaVe,
                                      )} đồng`
                            }}
                        </p>
                        <p class="py-1">{{ model.SoDienThoai || "(Trống)" }}</p>
                        <p class="py-1">{{ model.Vi?.tenVi || "(Trống)" }}</p>
                    </div>
                </div>
            </div>
            <div class="banve-btn">
                <DxButton
                    class="mt-3"
                    text="In Vé"
                    type="default"
                    styling-mode="contained"
                    @click="
                        BanVeThanhCong(),
                            this.thongTinThanhToan == null
                                ? layThongTinVe()
                                : layThongTinVeLoaiThanhToan()
                    "
                />
                <DxButton
                    class="mt-3"
                    text="Tiếp tục bán vé"
                    type="default"
                    styling-mode="text"
                    @click="
                        BanVeThanhCong(),
                            this.thongTinThanhToan == null
                                ? $router.push('/tabs/tab3')
                                : $router.push({
                                      path: '/Ban-Ve',
                                  })
                    "
                />
                <DxButton
                    class="mt-3"
                    text="Trang chủ"
                    type="default"
                    styling-mode="text"
                    @click="
                        VeTrangChu(),
                            this.thongTinThanhToan == null
                                ? $router.push('/tabs/tab1')
                                : $router.push({
                                      path: '/Ban-Ve',
                                  })
                    "
                />
            </div>
        </div>
        <div class="container-pregres" v-if="screen == 'error'">
            <div class="banve">
                <img class="img-error" src="@/assets/images/error.gif" alt="" />
                <p class="spinner-text color-error">Bán vé thất bại!</p>
                <p class="font-16 color-error py-1">{{ msgError }}</p>
            </div>
            <div class="banve-btn">
                <DxButton
                    id="btn-dangphattrien"
                    class="mt-3"
                    text="Thử lại"
                    type="default"
                    styling-mode="contained"
                    @click="thuLai()"
                />
                <DxButton
                    v-if="type == 'CoSoDo'"
                    class="mt-3"
                    text="Tiếp tục bán vé"
                    type="default"
                    styling-mode="text"
                    @click="
                        this.thongTinThanhToan == null
                            ? $router.push('/tabs/tab3')
                            : $router.push({
                                  path: '/Ban-Ve/Chon-Cho',
                                  query: {
                                      ...queryChoNgoi,
                                  },
                              })
                    "
                />
                <DxButton
                    v-if="type == 'KhongCoSoDo'"
                    class="mt-3"
                    text="Tiếp tục bán vé"
                    type="default"
                    styling-mode="text"
                    @click="
                        this.thongTinThanhToan == null
                            ? $router.push('/tabs/tab3')
                            : $router.push({
                                  path: '/Ban-Ve',
                              })
                    "
                />
            </div>
        </div>
    </div>
</template>
<script>
import { DxButton } from "devextreme-vue/button";
export default {
    props: {
        state: {
            default: "loading",
        },
        model: {},
        msgError: { type: String, default: null },
        type: {
            default: "CoSoDo",
        },
        thongTinThanhToan: {
            default: null,
        },
    },
    computed: {
        queryChoNgoi: {
            get() {
                return this.$store.state.Ve.queryChoNgoi;
            },
            set(data) {
                this.$store.commit("Ve/Set", {
                    key: "queryChoNgoi",
                    data: data,
                });
            },
        },
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            return this.$store.state.DVVTLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh;
        },
    },
    components: {
        DxButton,
    },
    data() {
        return {
            screen: "loading",
        };
    },
    watch: {
        state() {
            if (this.state == "success") {
                setTimeout(() => {
                    this.screen = "success";
                }, 2000);
            } else if (this.state == "inVe") {
                this.BanVeThanhCong();
                setTimeout(() => {
                    this.screen = "inVe";
                }, 2000);
                if (this.thongTinThanhToan != null) {
                    this.$router.push({
                        path: "/Ban-Ve/In-Ve",
                        query: {
                            maGiaoDich: this.thongTinThanhToan.maGiaoDich || "",
                            bienKiemSoat: this.thongTinThanhToan.bienKiemSoat || "",
                            gioXuatBenDuKien:
                                this.thongTinThanhToan.gioXuatBenDuKien || "",
                            in2Lien: this.thongTinThanhToan.in2Lien || "false",
                            hoTen: this.thongTinThanhToan.hoTen || "",
                            soDienThoai: this.thongTinThanhToan.soDienThoai || "",
                        },
                    });
                } else {
                    this.$router.push({
                        path: "/In-Ve-Lai-Xe-Ban",
                        query: {
                            maGiaoDich: this.model.MaGiaoDich,
                        },
                    });
                }
            } else if (this.state == "error") {
                this.screen = "error";
            }
        },
    },
    methods: {
        BanVeThanhCong() {
            this.$store.commit("NhanVienBanVe/Set", {
                key: "BanVeThanhCong",
                data: true,
            });
        },
        layThongTinVe() {
            this.$router.push({
                path: "/In-Ve-Lai-Xe-Ban",
                query: {
                    maGiaoDich: this.model.MaGiaoDich,
                },
            });
        },
        layThongTinVeLoaiThanhToan() {
            this.$router.push({
                path: "/Ban-Ve/In-Ve",
                query: {
                    maGiaoDich: this.thongTinThanhToan.maGiaoDich || "",
                    bienKiemSoat: this.thongTinThanhToan.bienKiemSoat || "",
                    gioXuatBenDuKien: this.thongTinThanhToan.gioXuatBenDuKien || "",
                    in2Lien: this.thongTinThanhToan.in2Lien || "false",
                    hoTen: this.thongTinThanhToan.hoTen || "",
                    soDienThoai: this.thongTinThanhToan.soDienThoai || "",
                },
            });
        },
        thuLai() {
            this.$emit("thulai");
        },
        VeTrangChu() {
            this.$store.commit("NhanVienBanVe/Set", {
                key: "VeTrangChu",
                data: true,
            });
        },
    },
    mounted() {
        // setTimeout(() => {
        //     this.screen = "success";
        // }, 2000);
    },
};
</script>
<style lang="css" scoped>
.xulybanve {
    position: fixed;
    inset: 0;
    z-index: 999;
    background: #f9f9f9;
    padding: 16px;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.container-pregres {
    height: 100%;
}
.spinner {
    display: flex;
    align-items: center;
}
.spinner-img {
    width: 66px;
    height: auto;
}
.spinner-text {
    font-size: 22px;
    font-weight: 600;
}
.progres {
    height: 10px;
    left: 5px;
    right: 5px;
    background-color: #dadece;
    border-radius: 5px;
    position: absolute;
    bottom: 0;
}

.progres-bar {
    height: 8px;
    background-color: #03a9f4;
    border-radius: 5px;
    animation: progres 2s infinite ease-in;
}

.banve {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 168px);
}

.img-error {
    width: 200px;
    height: auto;
}
.img-success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 128px;
    height: auto;
}
.banve-btn {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    background: #fff;
    left: 0;
    box-shadow: 0px -4px 9px #dadce0;
    right: 0;
    padding: 8px 16px;
}

.banve-img {
    width: 284px;
    position: relative;
}

.banve-info {
    width: 342px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dadce0;
    border-bottom: 1px solid #dadce0;
}
.banve-info-right {
    text-align: end;
}

@keyframes progres {
    0% {
        width: 0;
    }
    10% {
        width: 10%;
    }
    20% {
        width: 20%;
    }
    30% {
        width: 30%;
    }
    40% {
        width: 40%;
    }
    50% {
        width: 50%;
    }
    60% {
        width: 60%;
    }
    70% {
        width: 70%;
    }
    80% {
        width: 80%;
    }
    90% {
        width: 90%;
    }
    100% {
        width: 100%;
    }
}
</style>
