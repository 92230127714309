import ThoiGian from "./ThoiGian";
class DoanhNghiep extends ThoiGian {
    //Bao gồm nhà xe
    constructor(tenNhaXe, logoNhaXe, soDienThoai, tenLoaiXe) {
        super();
        this.tenNhaXe = tenNhaXe || "";
        this.logoNhaXe = logoNhaXe;
        this.soDienThoai = soDienThoai;
        this.tenLoaiXe = tenLoaiXe || "";
    }
    async layDanhSachViDienTu(idDoanhNghiep) {
        if (!idDoanhNghiep) {
            throw new Error("Không có idDoanhNghiep");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.QuanLyThongTinVe(
            window.$i18n.global.t("url.DanhSachViDienTu"),
            {
                idDoanhNghiep: idDoanhNghiep,
            }
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return [];
    }
    async layThongTinDieuKhoan(idNhaXe) {
        if (!idNhaXe) {
            console.log(
                "%c Không có idNhaXe ",
                "color: white; background-color: #95B46A",
                "=========>"
            );
            return;
        }

        window.$loading.show();
        let rs = await window.$Core.Api.Make(
            window.$i18n.global.t("url.ThongTinDieuKhoan"),
            {
                idNhaXe: idNhaXe,
            }
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return [];
    }
}
export { DoanhNghiep as default };
