import ThoiGian from "./ThoiGian";
class PhuongTien extends ThoiGian {
    constructor(
        guidXe,
        bienKiemSoat,
        soDienThoai,
        hangXe,
        guidLoaiXe,
        tenLoaiXe,
        soGhe,
        soGiuong,
        soChuyenThucHien,
        soChuyenDangKy,
        tanXuatDangKy,
        tenLuongTuyen,
        guidLuongTuyen
    ) {
        super();
        this.guidXe = guidXe;
        this.bienKiemSoat = bienKiemSoat;
        this.soDienThoai = soDienThoai;
        this.hangXe = hangXe;
        this.guidLoaiXe = guidLoaiXe;
        this.idLoaiXe = "";
        this.tenLoaiXe = tenLoaiXe;
        this.soGhe = soGhe || 0;
        this.soGiuong = soGiuong || 0;
        this.soChuyenThucHien = soChuyenThucHien || 0;
        this.soChuyenDangKy = soChuyenDangKy || 0;
        this.tanXuatDangKy = tanXuatDangKy;
        this.tenLuongTuyen = tenLuongTuyen;
        this.guidLuongTuyen = guidLuongTuyen;
        this.viTriDoXe = "";
    }
    async layThongTinXe(idChuyenDi) {
        window.$loading.show();
        let rs = await window.$Core.Api.Make(
            window.$i18n.global.t("url.ThongTinXe"),
            {
                idChuyenDi: idChuyenDi,
            }
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return rs.Data.message;
    }
    async layThongTinDichVu(idXe) {
        window.$loading.show();
        let rs = await window.$Core.Api.Make(
            window.$i18n.global.t("url.ThongTinDichVuXe"),
            {
                idXe: idXe,
            }
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return [];
    }
    async layDanhSachTangXe(idChuyenDi) {
        window.$loading.show();
        let rs = await window.$Core.Api.ChuyenDi(
            window.$i18n.global.t("url.DanhSachTangXe"),
            {
                IdChuyenDi: idChuyenDi,
            }
        ).Get();
        window.$loading.hide();
        return rs;
    }
    async layBaoCaoHoatDongBanVeTrenXe(tuNgay, denNgay) {
        if (!tuNgay) {
            throw new Error("Không có tuNgay");
        }
        if (!denNgay) {
            throw new Error("Không có denNgay");
        }

        window.$loading.show();
        let rs = await window.$Core.Api.Make(
            window.$i18n.global.t("url.BaoCaoHoatDongBanVeTrenXe"),
            {
                tuNgay: tuNgay,
                denNgay: denNgay,
            }
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return [];
    }
    async layBaoCaoDoanhThuBanVeTrenXe(tuNgay, denNgay) {
        if (!tuNgay) {
            throw new Error("Không có tuNgay");
        }
        if (!denNgay) {
            throw new Error("Không có denNgay");
        }
        window.$loading.show();
        let rs = await window.$Core.Api.Make(
            window.$i18n.global.t("url.BaoCaoDoanhThuBanVeTrenXe"),
            {
                tuNgay: tuNgay,
                denNgay: denNgay,
            }
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return [];
    }
    layDSPhuongTienDaVanHanh() {
        let self = this;
        const store = window.$Helper
            .Table({
                Id: "guidLenh",
                Url: window.$i18n.global.t("url.LayDSPhuongTienDaVanHanh"),
                UrlConfig: window.SP["API_BaseUrl_DieuDo"],
                Stt: false, // 'có dùng stt mặc định hay không'
            })
            .CustomStore()
            .Post({});
        return store.Store;
    }
}
class ChoNgoi extends PhuongTien {
    constructor(
        id,
        idTang,
        giaTien,
        tenCho,
        viTriCot,
        viTriHang,
        tenKieuCho,
        kyHieuKieuCho,
        idKieuCho,
        idLoaiCho,
        tenLoaiCho
    ) {
        super();
        this.id = id;
        this.idTang = idTang;
        this.giaTien = giaTien;
        this.tenCho = tenCho;
        this.viTriCot = viTriCot;
        this.viTriHang = viTriHang;
        this.tenKieuCho = tenKieuCho;
        this.kyHieuKieuCho = kyHieuKieuCho;
        this.idKieuCho = idKieuCho;
        this.idLoaiCho = idLoaiCho;
        this.tenLoaiCho = tenLoaiCho;
    }
    async layDanhSachKieuCho() {
        window.$loading.show();
        let rs = await window.$Core.Api.QuanLyThongTinVe(
            window.$i18n.global.t("url.DanhSachKieuCho")
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return [];
    }
    async layDanhSachTrangThaiCho(guidChuyenDi) {
        window.$loading.show();
        let rs = await window.$Core.Api.ChuyenDi(
            window.$i18n.global.t("url.DanhSachTrangThaiCho"),
            { GuidChuyenDi: guidChuyenDi }
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return [];
    }
    async layMaTranCho(idChuyenDi, idTang) {
        window.$loading.show();
        let rs = await window.$Core.Api.ChuyenDi(
            window.$i18n.global.t("url.MaTranChoNgoi"),
            {
                IdChuyenDi: idChuyenDi,
                IdTang: idTang,
            }
        ).Get();
        window.$loading.hide();
        if (rs.StatusCode === 200) {
            return rs.Data.data;
        }
        return [];
    }
    viTriDangChon(layMot) {
        return new Promise(function (resolve) {
            setTimeout(() => {
                let local = JSON.parse(localStorage.getItem("ChoDaChon"));
                if (layMot) {
                    if (!local) {
                        resolve(null);
                        return null;
                    }
                    resolve(local[0]);
                } else {
                    if (!local || (Array.isArray(local) && local.length == 0)) {
                        resolve(null);
                        return [];
                    }
                    resolve(local);
                }
            }, 200);
        });
    }
    hienThiChucNangTheoTrangThaiCho() {
        let dangChon = this.viTriDangChon();
        if (dangChon.length == 0) {
            return null;
        }
        let trangThai = {
            SoatVe: false,
            ThuTien: false,
            LoChuyen: false,
            XuongXe: false,
        };
        let dsIdCho = dangChon.map((m) => m.TrangThai);
        function kiemTraHienThiNutThuTien() {
            let check = true;
            for (let i = 0; i < dangChon.length; i++) {
                const e = dangChon[i];
                dsIdCho.forEach((id) => {
                    if (e.TrangThai != id) {
                        check = false;
                    }
                });
                if (!check) {
                    break;
                }
            }
            return check;
        }
        dangChon.forEach((e) => {
            if (dangChon.length > 1) {
                // Thu tiền dc chọn một hoặc nhiều và cùng 1 trạng thái
                if (
                    e.TrangThai ==
                        parseInt(window.$i18n.global.t("trangThai.Trong")) ||
                    e.TrangThai ==
                        parseInt(
                            window.$i18n.global.t("trangThai.DangThanhToan")
                        )
                ) {
                    if (kiemTraHienThiNutThuTien()) {
                        trangThai.ThuTien = true;
                    }
                }
            } else {
                // Còn lại các chức năng khác chỉ chọn 1
                if (e.ChoDaChon) {
                    if (
                        e.TrangThai ==
                            parseInt(
                                window.$i18n.global.t("trangThai.DaNgoi")
                            ) ||
                        e.TrangThai ==
                            parseInt(window.$i18n.global.t("trangThai.DaMua"))
                    ) {
                        trangThai.SoatVe = true;
                    }
                    if (
                        e.TrangThai ==
                            parseInt(
                                window.$i18n.global.t("trangThai.Trong")
                            ) ||
                        e.TrangThai ==
                            parseInt(
                                window.$i18n.global.t("trangThai.DangThanhToan")
                            )
                    ) {
                        trangThai.ThuTien = true;
                    }
                    if (
                        e.TrangThai ==
                            parseInt(
                                window.$i18n.global.t("trangThai.DaDat")
                            ) ||
                        e.TrangThai ==
                            parseInt(window.$i18n.global.t("trangThai.DaMua"))
                    ) {
                        trangThai.LoChuyen = true;
                    }
                    if (
                        e.TrangThai ==
                        parseInt(window.$i18n.global.t("trangThai.DaNgoi"))
                    ) {
                        trangThai.XuongXe = true;
                    }
                } else {
                    trangThai = {
                        SoatVe: false,
                        ThuTien: false,
                        LoChuyen: false,
                        XuongXe: false,
                    };
                }
            }
        });
        return trangThai;
    }
}
export { PhuongTien as default, ChoNgoi };
