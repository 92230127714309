<template>
    <div class="mb-3">
        <div class="font-16 text-xs-left pb-1 pt-2 font-bold">
            Chọn hình thức thu tiền
        </div>
        <div
            class="row justify-center align-center gray--text"
            v-if="DanhSachVi.length == 0"
        >
            Không tải được ví điện tử. Vui lòng{{ "\xa0" }}
            <span class="thu-lai" @click="InitPage()">thử lại!</span>
        </div>
        <div v-else class="row wrap justify-space-around align-center">
            <div
                v-for="(item, i) in DanhSachVi"
                :key="i"
                @click="chonVi(item)"
                :class="`mt-2 full-width xs6`"
            >
                <div
                    :class="`mx-2 hinhthucthu-style ${
                        item.active ? 'active' : ''
                    }`"
                >
                    <div class="row justify-center align-center full-height">
                        <div>
                            <div
                                class="
                                    row
                                    justify-center
                                    align-center
                                    full-height
                                "
                            >
                                <img
                                    v-if="item.logoUrl"
                                    :src="item.logoUrl"
                                    style="width: auto; height: 25px"
                                />
                                <i
                                    v-else
                                    class="
                                        mdi mdi-cash-multiple
                                        success--text
                                        px-2
                                    "
                                ></i>
                                <div
                                    class="pl-1"
                                    style="
                                        font-size: 9px;
                                        text-transform: uppercase;
                                    "
                                    v-if="!item.logoUrl"
                                >
                                    {{ item.tenVi }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { onIonViewDidEnter } from "@ionic/vue";
import DoanhNghiep from "../../class/DoanhNghiep";
export default {
    props: {
        LayDanhSachVi: { type: Boolean, default: true },
        GetData: { type: Boolean, default: false },
    },
    data() {
        return {
            DanhSachVi: [],
        };
    },
    watch: {
        GetData: {
            handler: function () {
                if (this.GetData) {
                    this.InitPage();
                }
            },
            immediate: true,
        },
        LayDanhSachVi: {
            handler: function () {
                if (!this.LayDanhSachVi) {
                    this.InitPage();
                }
            },
            immediate: true,
        },
    },
    methods: {
        chonVi(item) {
            this.DanhSachVi = this.DanhSachVi.map((e) => {
                if (e.id === item.id) {
                    e.active = true;
                } else {
                    e.active = false;
                }
                return e;
            });
            this.$emit("ChonVi", item, this.DanhSachVi || []);
        },
        async InitPage() {
            try {
                let danhSachViDienTu =
                    await new DoanhNghiep().layDanhSachViDienTu(
                        this.$route.query.guidDoanhNghiep
                    );
                if (this.LayDanhSachVi) {
                    if (danhSachViDienTu.length > 0) {
                        danhSachViDienTu.unshift({
                            id: "",
                            slogan: "Thu tiền mọi nơi",
                            tenVi: "Tiền mặt/Cash change",
                        });
                        danhSachViDienTu.forEach((e) => {
                            e.active = false;
                        });
                        this.DanhSachVi = danhSachViDienTu;
                        if (this.DanhSachVi.length > 0) {
                            this.chonVi(this.DanhSachVi[0]);
                        }
                    } else {
                        this.DanhSachVi = [
                            {
                                id: "",
                                slogan: "Thu tiền mọi nơi",
                                tenVi: "Tiền mặt/Cash change",
                            },
                        ];
                        this.chonVi(this.DanhSachVi[0]);
                    }
                } else {
                    this.DanhSachVi = [
                        {
                            id: "",
                            slogan: "Thu tiền mọi nơi",
                            tenVi: "Tiền mặt/Cash change",
                        },
                    ];
                    this.chonVi(this.DanhSachVi[0]);
                }
            } catch (error) {
                console.log("🚀", error);
                this.DanhSachVi = [
                    {
                        id: "",
                        slogan: "Thu tiền mọi nơi",
                        tenVi: "Tiền mặt/Cash change",
                    },
                ];
                this.chonVi(this.DanhSachVi[0]);
            }
        },
    },
    mounted() {
        // this.getData();
        let arrEvent = this.emitter.all;
        for (const [key, value] of arrEvent) {
            if (key == "LayDanhSachVi") {
                this.emitter.off("LayDanhSachVi");
            }
        }
        this.emitter.on("LayDanhSachVi", () => {
            this.InitPage();
        });
    },
};
</script>
<style lang="scss" scoped>
.hinhthucthu-style {
    min-width: 72px;
    height: 36px;
    box-shadow: $box_shadow;
    border-radius: $border_radius;
}
.active {
    border: 2px solid $primary;
}
.thu-lai {
    text-decoration: underline;
    color: #03a9f4;
}
</style>