<template>
    <div>
        <div v-if="hienThiSoTien">
            <div class="font-14 font-bold pb-3">Số tiền thanh toán</div>
            <div class="divider"></div>
            <div class="container-xs">
                <div class="row pt-2 align-center justify-space-between">
                    <div>Tổng tiền:</div>
                    <div>{{ `${$MoneyFormat(model.TongTien)}đ` }}</div>
                </div>
                <div class="row pt-3 align-center justify-space-between">
                    <div>Chiết khấu:</div>
                    <div>{{ `${$MoneyFormat(model.ChietKhau)}đ` }}</div>
                </div>
                <div class="row pt-3 align-center justify-space-between">
                    <div>Thực thu:</div>
                    <div class="font-bold">
                        {{ `${$MoneyFormat(model.ThucThu)}đ` }}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="hienThiThongTin">
            <div class="font-14 font-bold pb-3 pt-3">Thông tin thanh toán</div>
            <div class="divider"></div>
            <div class="container-xs">
                <div class="row pt-2 align-center justify-space-between">
                    <div>Hình thức thanh toán:</div>
                    <div>{{ model.HinhThucThanhToan }}</div>
                </div>
                <div class="row pt-3 align-center justify-space-between">
                    <div>Số điện thoại:</div>
                    <div>{{ model.SoDienThoai }}</div>
                </div>
                <div class="row pt-3 align-center justify-space-between">
                    <div>Họ và tên:</div>
                    <div>{{ model.HoTen }}</div>
                </div>
                <div class="row pt-3 align-center justify-space-between">
                    <div>Email:</div>
                    <div>{{ model.Email }}</div>
                </div>
                <div class="row pt-3 align-center justify-space-between">
                    <div>Vị trí chỗ:</div>
                    <div>{{ model.ViTriCho }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        hienThiSoTien: { type: Boolean, default: true },
        hienThiThongTin: { type: Boolean, default: true },
        dialog: { type: Boolean, default: false },
        data: { type: Object, default: () => {} },
    },
    data() {
        return {
            model: {
                TongTien: 0,
                ChietKhau: 0,
                ThucThu: 0,
                HinhThucThanhToan: "",
                SoDienThoai: "",
                HoTen: "",
                Email: "",
                ViTriCho: "",
            },
        };
    },
    watch: {
        dialog: {
            handler: function () {
                if (this.dialog == true) {
                    this.model.ThucThu = parseInt(this.data.ThucThu) || 0;
                    this.model.TongTien = parseInt(this.data.TongTien) || 0;
                    this.model.ChietKhau = parseInt(this.data.ChietKhau) || 0;
                    this.model.HinhThucThanhToan = this.data.HinhThucThanhToan;
                    this.model.SoDienThoai = this.data.SoDienThoai;
                    this.model.HoTen = this.data.HoTen;
                    this.model.Email = this.data.Email;
                    this.model.ViTriCho = this.data.ViTriCho;
                }
            },
            deep: true,
        },
    },
};
</script>
<style lang="scss" scoped>
.divider {
    border-bottom: 1px solid $border;
}
</style>