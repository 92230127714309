<template>
    <div class="row align-center" style="flex-wrap: wrap">
        <ion-chip
            :style="`${
                item.active ? '--background: #03a9f4; --color: white' : ''
            }`"
            v-for="(item, index) in data"
            :key="index"
            class="pa-2 mr-2 mb-2"
            @click="chonGoiY(item)"
        >
            <ion-label>{{ item.text }}</ion-label>
        </ion-chip>
    </div>
</template>
<script>
import { IonChip, IonLabel } from "@ionic/vue";
export default {
    components: { IonChip, IonLabel },
    props: { data: { type: Array, default: () => [] } },
    setup(props) {
        return {
            data: props.data,
        };
    },
    methods: {
        chonGoiY(item) {
            if (!item.active || item.active == false) {
                this.data = this.data.map((m) => {
                    if (m.id == item.id) {
                        m.active = !m.active;
                    } else {
                        m.active = false;
                    }
                    return m;
                });
            }
            this.$emit("ChonGoiY", item);
        },
    },
    created() {},
    mounted() {},
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
.tag {
    color: rgba(68, 82, 94, 0.87);
    background-color: #eef1f3;
    border-radius: 50px;
}
.rounder:active {
    background-color: #dedede;
}
</style>